import { render, staticRenderFns } from "./VesselAccidentIncident.vue?vue&type=template&id=4d180249&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./VesselAccidentIncident.vue?vue&type=script&lang=js&"
export * from "./VesselAccidentIncident.vue?vue&type=script&lang=js&"
import style0 from "./VesselAccidentIncident.vue?vue&type=style&index=0&id=4d180249&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d180249",
  null
  
)

export default component.exports
<template xmlns="http://www.w3.org/1999/html">
  <div>
    <div class="row no-gutters">
      <div class="col pb-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setAccidentIncidentToTable(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setAccidentIncidentToTable(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setAccidentIncidentToTable(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>

    </div>
    <div class="col-lg-12 ">
      <div class="row no-gutters">
        <div class="col-md-auto">
          <label class="col-form-label pr-2 font-weight-bold">Filter By: </label>
        </div>
        <div class="col-md-auto px-1">
          <select class="form-control form-control-sm text-uppercase" id="fleetList" v-model="params.fleet_id">
            <!--                <option :value="null">—— SELECT PORT ——</option>-->
            <option :value="null">-- ALL FLEET --</option>
            <template v-for="fleet in fleetKeys">
              <option :value="fleet.id" v-if="fleet.name !== 'N/A' && fleet.code !== 'N/A'">
                {{fleet.name ? fleet.name.toUpperCase() : ''}}
              </option>
            </template>
          </select>
        </div>

          <div class="col-3 pb-2">
            <input type="text" name="vesselSearch" class="form-control form-control-sm" v-model="params.search" placeholder="Search Vessel">
          </div>
          <div class="pb-2">
            <button id="downloadResult" class="ml-2 mr-2 e-btn e-btn-blue-gray force-white-all"
                    @click="downloadResult"
            >
              <font-awesome-icon class="mr-1" icon="download"/>
              Download
            </button>
          </div>
      </div>
    </div>
    <div class="row col-12 p-0 m-0">
      <table class="table text-center table-sm small e-tbl">
        <thead class="bg-white">
        <tr >
          <th class="align-middle" style="width: 1em" scope="col">#</th>
          <th class="text-uppercase align-middle" scope="col">VESSEL NAME</th>
          <th class="text-uppercase align-middle" scope="col">FLEET</th>
          <th class="text-uppercase align-middle" scope="col">MANAGEMENT BRANCH OFFICE</th>
          <template v-for="year in years">
            <th class="text-uppercase align-middle" scope="col">{{ year }}</th>
          </template>
        </tr>
        </thead>
        <tbody>
        <tr v-if="loading">
          <td colspan="100%">
            <app-loading></app-loading>
          </td>
        </tr>

        <template v-else>
          <tr v-for="(data, index) in vesselAccidentIncidentYearlyCounts" class="vessel-accident-incident-count no-select">
            <td class="align-middle">{{ index + pagination.from }}</td>
            <td class="align-middle text-uppercase">{{ data.name }}</td>
            <td class="align-middle text-uppercase">{{ data.fleet_name }}</td>
            <td class="align-middle text-uppercase">{{ data.management_office }}</td>

            <template v-for="year_data in years">
              <td class="align-middle text-uppercase number" v-if="data[year_data + '_accident_incident_count'] === 0 || data[year_data + '_accident_incident_count'] === null">
                {{ data[year_data + '_accident_incident_count'] ?? 0 }}
              </td>
              <td class="align-middle text-uppercase number" v-else>
                <a href="#" @click="getAccidentIncidentForThisCount(data, year_data)">{{ $route.params.feature === 'delay_hours' ? Number(data[year_data + '_accident_incident_count']).toFixed(2) : data[year_data + '_accident_incident_count']}}</a>
              </td>
            </template>
          </tr>
        </template>
        </tbody>
      </table>
    </div>


    <div class="modal fade" id="vesselAccidentIncidentCountModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xxl modal-dialog-centered" style="max-width: 80vw;">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ modalVesselName }} - ACCIDENT/INCIDENT REPORTS</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="small e-tbl">
              <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">

              <template v-if="$route.params.feature !== 'delay_hours'">
                <tr>
                  <th class="align-middle text-center" style="width: 1em" scope="col">#</th>
                  <th class="align-middle text-center" scope="col">VESSEL</th>
                  <th class="align-middle text-center" scope="col">MANAGER</th>
                  <th class="align-middle text-center" rowspan="2" scope="col">EVENT CODE 1</th>
                  <th class="align-middle text-center" scope="col">REPORT TITLE</th>
                  <th class="align-middle text-center" scope="col">EVENT DATE</th>
                  <th class="align-middle text-center" scope="col">ACTION</th>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <th class="align-middle text-center" rowspan="2" style="width: 1em" scope="col">#</th>
                  <th class="align-middle text-center" rowspan="2" scope="col">VESSEL</th>
                  <th class="align-middle text-center" rowspan="2" scope="col">MANAGER</th>
                  <th class="align-middle text-center" rowspan="2" scope="col">EVENT CODE 1</th>
                  <th class="align-middle text-center" rowspan="2" scope="col">REPORT TITLE</th>
                  <th class="align-middle text-center" rowspan="2" scope="col">EVENT DATE</th>
                  <th class="align-middle text-center" scope="col" colspan="4">SCHEDULE DELAY <br>
                    <div class="font-weight-bold" style="font-size: 14px">({{ getTotalHours(vesselAccidentIncidentYearlyCountsList) }} Hour/s) </div>
                  </th>
                  <th rowspan="2" class="align-middle text-center" scope="col">ACTION</th>
                </tr>
                <tr>
                  <th>Day/s</th>
                  <th>Hour/s</th>
                  <th>Minute/s</th>
                  <th>Total Hour/s</th>
                </tr>
              </template>

              </thead>
              <tbody>
              <template v-if="modalLoading">
                <tr>
                  <td colspan="100%">
                    <app-loading></app-loading>
                  </td>
                </tr>
              </template>
              <template v-else>
                  <tr v-for="(data,index) in vesselAccidentIncidentYearlyCountsList ">
                    <td class="align-middle text-center text-uppercase">{{ index + 1 }}</td>
                    <td class="align-middle text-center text-uppercase">{{ data.vessel?.name }}</td>
                    <td class="align-middle text-center text-uppercase">{{ data.management_office?.name }}</td>
                    <td class="align-middle text-center text-uppercase" :class="['bg-' + eventOneColorCode[data.event?.event_one?.code].color_code]">{{ data.event?.event_one?.name }}</td>
                    <td class="align-middle text-center text-uppercase">{{ data.title }}</td>
                    <td class="align-middle text-center text-uppercase">{{ data.event_date | moment('D MMMM YYYY') }}</td>

                    <template v-if="$route.params.feature === 'delay_hours'">
                      <td class="align-middle text-center text-uppercase number">{{ data?.delay_sched_day ?? 0 }}</td>
                      <td class="align-middle text-center text-uppercase number">{{ data?.delay_sched_hour ?? 0 }}</td>
                      <td class="align-middle text-center text-uppercase number">{{ data?.delay_sched_minute ?? 0 }}</td>
                      <td class="align-middle text-center text-uppercase number">{{ setTotalHours(data?.delay_sched_day ?? 0, data?.delay_sched_hour ?? 0, data?.delay_sched_minute ?? 0) }}</td>
                    </template>

                    <td class="align-middle text-center text-uppercase">
                      <button class="e-btn e-btn-blue e-btn-sm e-text-white my-1" @click="showReport(data.accident_incident_id)">
                        <font-awesome-icon icon="eye"  />
                        View
                      </button>
                    </td>

                  </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {permissionMixin} from "@/mixins/permissionMixins";
import {mapActions, mapGetters} from "vuex";
import AppLoading from "@/components/elements/AppLoading.vue";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import {VesselService} from "@/services/VesselService";

export default {
  name: 'VesselAccidentIncident',
  mixins: [permissionMixin, PaginationMixin],
  data() {
    return {
      loading: true,
      params : {
        feature : this.$route.params.feature,
        fleet_id: null,
      },
      modalLoading : false,
      modalVesselName : '',
      eventOneColorCode : {
        a : { // 1
          color      : '#0000ff',
          color_code : '0000ff'
        },
        b : { // 2
          color      : '#0000ff',
          color_code : '0000ff'
        },
        c : { // 3
          color      : '#ff0000',
          color_code : 'ff0000'
        },
        d : { // 4
          color      : '#ffbf00',
          color_code : 'ffbf00'
        },
        e : { // 5
          color      : '#c75912',
          color_code : 'c75912'
        },
        f : { // 6
          color      : '#c75912',
          color_code : 'c75912'
        },
        g : { // 7
          color      : '#7030a0',
          color_code : '7030a0'
        },
        h : { // 8
          color      : '#7030a0',
          color_code : '7030a0'
        },
        i : { // 9
          color      : '#548235',
          color_code : '548235'
        },
        j : { // 10
          color      : '#ff0066',
          color_code : 'ff0066'
        },
        k : { // 11
          color      : '#2f5496',
          color_code : '2f5496'
        },
        l : { // 12
          color      : '#2f5496',
          color_code : '2f5496'
        },
        m : { // 13
          color      : '#a6a6a6',
          color_code : 'a6a6a6'
        }
      },
      downloadExcelResult : false
    }
  },
  components: {
    AppLoading,
  },
  methods: {
    ...mapActions([
      'getVesselAccidentIncidentYearlyCounts',
      'getVesselAccidentIncidentYearlyCountsList',
      'getFleetListKeys'
    ]),
    async downloadResult() {
      let params = Object.assign({}, this.params);
      params.excel = 1;

      const SwalTitleMapper = {
        'number_of_report' : 'NUMBER OF REPORT',
        'number_of_delay'  : 'NUMBER OF DELAY',
        'delay_hours'      : 'DELAY HOURS',
      }


      swal.fire({
        'title': `DOWNLOADING ${SwalTitleMapper[this.$route.params.feature]}<br>Please wait.....`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })
      swal.showLoading()

      const downloadResponse = await VesselService.downloadVesselAccidentIncidentYearlyCount(params);
      swal.close()
      let url = window.URL.createObjectURL(new Blob([downloadResponse.data],{type:'application/vnd.ms-excel'}))
      let filename=`${this.$route.params.feature}_${Date.now()}.xlsx`;
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
    async setAccidentIncidentToTable(page=1) {
      this.loading = true;
      this.params.page = page;
      await this.getVesselAccidentIncidentYearlyCounts(this.params)
      this.loading = false;
    },
    async getAccidentIncidentForThisCount(data, year) {
      this.modalLoading = true;
      $('#vesselAccidentIncidentCountModal').modal('show');
      this.modalVesselName = data.name;
      await this.getVesselAccidentIncidentYearlyCountsList({
        vessel_id : data.id,
        feature   : this.$route.params.feature,
        year      : year
      });

      this.modalLoading = false;
    },
    showReport(accidentIncidentId) {
      const routeName = this.$route.params.feature === 'number_of_report' ? 'AccidentIncidentReport' : 'Loss'
      const route= this.$router.resolve(
        {
          name: routeName,
          params: {
            id: accidentIncidentId,
          },
        },
      )
      window.open(route.href,'_blank')
    },
    setTotalHours(day = 0, hours = 0, min = 0) {
      const totalHoursInDays= day * 24
      const totalHoursInMins= (min / 60)

      return Number((totalHoursInDays + hours) + totalHoursInMins).toFixed(2)
    },
    getTotalHours(vesselAccidentIncidentYearlyCountsList) {
      let total_hours = 0;

      vesselAccidentIncidentYearlyCountsList.forEach(data => {
        const totalHrsInDay = parseFloat(data.delay_sched_day ?? 0) * 24;
        const totalHrsInMin = parseFloat(data.delay_sched_minute ?? 0) / 60;
        total_hours += parseFloat(Number(totalHrsInDay + parseFloat(data.delay_sched_hour ?? 0) + totalHrsInMin).toFixed(2));
      })

      return Number(total_hours).toFixed(2);
    }
  },
  async created() {
    await this.getFleetListKeys()
    this.loading = false;
    this.setVesselsAccidentIncident = _.debounce(this.setAccidentIncidentToTable, 750)
    await this.setAccidentIncidentToTable();
  },
  computed: {
    ...mapGetters([
      'vesselAccidentIncidentYearlyCounts',
      'vesselAccidentIncidentYearlyCountsList',
      'pagination',
      'fleetKeys'
    ]),
    years() {
      const currentYear = new Date().getFullYear();
      const tenYearsFromNow = currentYear - 10;
      return Array.from({ length: currentYear - tenYearsFromNow + 1 }, (_, index) => tenYearsFromNow + index);
    }
  },
  watch: {
    'params.search'() {
      this.setVesselsAccidentIncident()
    },
    'params.fleet_id'() {
      this.setAccidentIncidentToTable()
    },
  },
}
</script>

<style scoped>
tr th, tr td.number, tr td.number a {
  font-size: 1rem;
  font-weight: bold;
}
</style>



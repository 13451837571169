<template>
  <div class="row">
    <div class="col-12">
      <h4 class="mb-3 text-center">PSC Yearly Overview</h4>
      <template v-if="loading">
        <app-loading></app-loading>
      </template>
      <template v-else>
        <p class="lead text-black-50 mb-0">Number of PSC Inspection and No Deficiency Rate of KRBS vessels in {{currentYearListing.year}}</p>
        <table class="table text-center table-sm small e-tbl">
          <thead class="bg-white">
          <tr>
            <th class="align-middle text-center" scope="col">INSPECTION</th>
            <th class="align-middle text-center" scope="col"></th>
            <th class="align-middle" scope="col">{{currentYearListing.year}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="text-center">Number of Inspection with no Deficiency</td>
            <td>(A)</td>
            <td class="bg-light">{{currentYearListing.noDeficiencyCount}}</td>
          </tr>
          <tr>
            <td class="text-center">Number of Inspection</td>
            <td> (B) </td>
            <td class="bg-light">{{currentYearListing.inspectionCount}}</td>
          </tr>
          <tr>
            <td class="text-center">No Deficiency Rate Average (All Vessels) </td>
            <td> (A)/(B) </td>
            <td class="bg-light">{{currentYearListing.noDeficiencyRate}} %</td>
          </tr>
          </tbody>
        </table>
      </template>
    </div>
    <div class="col-12">
      <p class="lead text-black-50 mb-0">No Deficiency Rate (NDR) in the past years</p>
      <template v-if="loading">
        <app-loading></app-loading>
      </template>
      <template v-else>
        <table class="table text-center table-sm small e-tbl">
          <thead class="bg-white">
          <tr>
            <th class="align-middle" scope="col">YEAR</th>
            <th class="align-middle" v-for="year in listings.years" scope="col">{{year}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Nbr Inspection (B)</td>
            <template v-for="(inspection,index) in listings.inspectionCount">
              <td v-if="(index + 1) ==listings.inspectionCount.length" class="bg-light">
                {{inspection}}
              </td>
              <td v-else>
                {{inspection}}
              </td>
            </template>
          </tr>
          <tr>
            <td>Nbr Deficiency Inspection</td>
            <template v-for="(deficiency,index) in listings.deficiencyCount">
              <td v-if="(index + 1) ==listings.deficiencyCount.length" class="bg-light">
                {{deficiency}}
              </td>
              <td v-else>
                {{deficiency}}
              </td>
            </template>
          </tr>
          <tr>
            <td>Nbr No Deficiency Inspection (A)</td>
            <template v-for="(noDeficiency,index) in listings.noDeficiencyCount">
              <td v-if="(index + 1) ==listings.noDeficiencyCount.length" class="bg-light">
                {{noDeficiency}}
              </td>
              <td v-else>
                {{noDeficiency}}
              </td>
            </template>
          </tr>
          <tr>
            <td>NDR (A) / (B) (%)</td>
            <template v-for="(noDeficiencyRate,index) in listings.noDeficiencyRate">
              <td v-if="(index + 1) ==listings.noDeficiencyRate.length" class="bg-light">
                {{noDeficiencyRate}} %
              </td>
              <td v-else>
                {{noDeficiencyRate}} %
              </td>
            </template>
          </tr>
          </tbody>
        </table>
      </template>

    </div>
    <div class="col-12">
      <p class="lead text-black-50 mb-0">No Deficiency Rate (%)</p>
    </div>
    <div class="col-6">
      <div id="chart">
        <app-loading  v-if="loading"></app-loading>
        <template v-else>
          <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
        </template>
      </div>
    </div>
    <div class="col-6">
      <div id="chart">
        <app-loading  v-if="loading"></app-loading>
        <template v-else>
          <apexchart type="line" height="350" :options="chartOptionLine" :series="seriesLine"></apexchart>
        </template>
      </div>
    </div>

    <div class="col-12">
      <p class="lead text-black-50 mb-0">Number of Deficiency</p>
    </div>
    <div class="col-6">
      <div id="chart">
        <app-loading  v-if="loading"></app-loading>
        <template v-else>
          <apexchart type="line" height="350" :options="deficiencyInspectionChartOptions" :series="deficiencyInspectionSeries"></apexchart>

        </template>
      </div>
    </div>
    <div class="col-6">
      <div id="chart">
        <app-loading  v-if="loading"></app-loading>
        <template v-else>
          <apexchart type="line" height="350" :options="deficiencyChartOptions" :series="deficiencySeries"></apexchart>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import AppLoading from "@/components/elements/AppLoading.vue";

export default {
  name: "YearlyOverview",
  components: {AppLoading},
  data: function () {
    return {
      series: [
        {
          name: 'Deficiency Inspection',
          type: 'column',
          data: [],
        },
        {
          name: 'No Deficiency',
          type: 'column',
          data: []

        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: true
        },
        fill: {
          colors: ['#ff3736', '#00E396D8'],
        },
        colors: ['#ff3736', '#00E396D8'],
        dataLabels: {
          formatter: function (val, opts) {
            return parseInt(val);
          },
          enabled: false
        },
        stroke: {
          width: [1, 1, 4]
        },
        xaxis: {
          categories: [],
          colors: ['#ff3736', '#00E396D8']
        },
        yaxis: [
          {
            seriesName: 'No of  Inspection',
            opposite: false,
            labels: {
              style: {
                colors: '#000000',
              }
            },
            title: {
              text: "Number of  Inspection",
              style: {
                color: '#03944c',
              }
            },
          },
          {
            seriesName: 'Deficiency Inspection',
            opposite: true,
            title: {
              text: "Number of  Deficiency",
              style: {
                color: '#ff3736',
              }
            },
          },
        ],
        tooltip: {
          custom: function ({series, seriesIndex, dataPointIndex, w}) {
            var totalInsection = series[0][dataPointIndex] + series[1][dataPointIndex];

            var inspection = `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                            <span class="apexcharts-tooltip-marker" style="background-color: transparent;"></span>
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                <div class="apexcharts-tooltip-y-group">
                                    <span class="apexcharts-tooltip-text-y-label">Total Inspection :</span>
                                    <span class="apexcharts-tooltip-text-y-value">${totalInsection}</span>
                                </div>
                            </div>
                        </div>`

            var deficiencyInpsection = `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                            <span class="apexcharts-tooltip-marker" style="background-color: #ff3736;"></span>
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                <div class="apexcharts-tooltip-y-group">
                                    <span class="apexcharts-tooltip-text-y-label">Deficiency Inspection :</span>
                                    <span class="apexcharts-tooltip-text-y-value">${series[0][dataPointIndex]}</span>
                                </div>
                            </div>
                        </div>`

            var noDeficiency = `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                            <span class="apexcharts-tooltip-marker" style="background-color: #00E396D8;"></span>
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                <div class="apexcharts-tooltip-y-group">
                                    <span class="apexcharts-tooltip-text-y-label">No Deficiency :</span>
                                    <span class="apexcharts-tooltip-text-y-value">${series[1][dataPointIndex]}</span>
                                </div>
                            </div>
                        </div>`
            return `<div className="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; padding: 10px; background: #eae9e9">
                        ${w.globals.lastXAxis.categories[dataPointIndex]} PSC INSPECTION
                    </div>`+ inspection+deficiencyInpsection+noDeficiency;
          },
          // marker: {
          //   fillColors: ['#ff3736', '#00E396D8'],
          // }
        }
      },
      seriesLine: [
        {
          name: 'No Deficiency Rate',
          type: 'line',
          data: []
        }
      ],
      chartOptionLine: {
        type: 'line',
        zoom: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        colors: ["#fdae03"],
        xaxis: {
          categories: [],
        },
        yaxis: {
          seriesName: 'No Deficiency Rate',
          opposite: true,
          min: 0,
          max: 100,
          labels: {
            style: {
              colors: '#000000',
            }
          },
          title: {
            text: "NO Deficiency Rate (%)",
            style: {
              color: '#fdae03',
            }
          },
        },
        tooltip: {
          y: {
            formatter: function (value, {series, seriesIndex, dataPointIndex, w}) {
              if (seriesIndex === 0) {
                return value.toFixed(2) + "%"
              }
            }
          }
        }
      },

      deficiencySeries: [
        {
          name: 'Total Deficiency',
          type: 'column',
          data: [],
        }
      ],
      deficiencyInspectionSeries: [
        {
          name: 'Total Deficiency per Inspection',
          type: 'column',
          data: [],
        }
      ],
      deficiencyChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: true
        },
        fill: {
          colors: ['#ff7c24'],
        },
        colors: ['#ff7c24'],
        dataLabels: {
          formatter: function (val, opts) {
            return parseInt(val);
          },
          enabled: false
        },
        stroke: {
          width: [1, 1, 4]
        },
        xaxis: {
          categories: [],
          colors: ['#ff7c24']
        },
        yaxis: [
          {
            seriesName: 'No of Deficiency',
            opposite: false,
            labels: {
              style: {
                colors: '#000000',
              }
            },
            title: {
              text: "Number of Deficiency",
              style: {
                color: '#ff3736',
              }
            },
          }
        ]
      },
      deficiencyInspectionChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: true
        },
        fill: {
          colors: ['#ff3736'],
        },
        colors: ['#ff3736'],
        dataLabels: {
          formatter: function (val, opts) {
            return parseInt(val);
          },
          enabled: false
        },
        stroke: {
          width: [1, 1, 4]
        },
        xaxis: {
          categories: [],
          colors: ['#ff3736']
        },
        yaxis: [
          {
            seriesName: 'No of Deficiency',
            opposite: false,
            labels: {
              style: {
                colors: '#000000',
              }
            },
            title: {
              text: "Number of Deficiency per Inspection",
              style: {
                color: '#ff3736',
              }
            },
          }
        ]
      },


      year: new Date().getFullYear(),
      listings: {},
      currentYearListing: {},
      years: [],
      loading: false
    }
  },
  computed:{
    ...mapGetters(['inspectionByYear','inspectionBySpan', 'inspectionDeficiencyCodeGroup'])
  },
  methods:{
    ...mapActions(['getInspectionReportByYear','getInspectionReportBySpan', 'getInspectionDeficiencyCodeGroup']),
    setAllDeficiencyCountByYears() {
      let deficiencySeriesData = [];
      this.inspectionDeficiencyCodeGroup.map(item => item.years).forEach((years) => {
        for (let year in years) {
          let yearData = deficiencySeriesData.filter(data => data.year === year);
          if (yearData.length === 0) {
            deficiencySeriesData.push({
              year : year,
              value : parseInt(years[year])
            });
          } else {
            yearData[0].value += parseInt(years[year]);
          }
        }
      });
      this.deficiencySeries[0].data = deficiencySeriesData.map(data => data.value);

    }
  },
  mounted() {
  },
  async created() {
    this.loading=true
    await this.getInspectionReportBySpan({option:'past_years',year_span:10})
    await this.getInspectionDeficiencyCodeGroup();
    this.setAllDeficiencyCountByYears()
    if(this.inspectionBySpan){
      const entries=Object.entries(this.inspectionBySpan);
      let categories=[];
      let inspectionCount=[]
      let noDeficiencyCount=[]
      let noDeficiencyRate=[]
      let deficiencyCount=[]
      let totalInspection=[]
      let nbr = []
      const totalDeficiency = Array(this.deficiencySeries[0].data)
      console.log(totalDeficiency[0][2])
      entries.map(([key,value]= entry, index)=>{
        categories.push(key)
        noDeficiencyCount.push(value.no_deficiency_count)
        noDeficiencyRate.push(parseInt(value.no_deficiency_rate))
        deficiencyCount.push(value.with_deficiency_count)
        inspectionCount.push(value.inspection_count)
        nbr.push(Number(value.inspection_count === 0 ? 0 : totalDeficiency[0][index] / value.inspection_count).toFixed(2))
        totalInspection.push(value.with_deficiency_count + value.no_deficiency_count);
      })
      this.series[0].data=deficiencyCount
      this.series[1].data=noDeficiencyCount
      this.seriesLine[0].data=noDeficiencyRate
      this.deficiencyInspectionSeries[0].data = nbr;
      this.listings={
        years:categories,
        noDeficiencyCount:noDeficiencyCount,
        noDeficiencyRate:noDeficiencyRate,
        inspectionCount:inspectionCount,
        deficiencyCount:deficiencyCount
      }
      this.chartOptions.xaxis.categories=categories
      this.chartOptionLine.xaxis.categories=categories
      this.deficiencyChartOptions.xaxis.categories=categories
      this.deficiencyInspectionChartOptions.xaxis.categories=categories
      this.chartOptions.yaxis[0].max=350
      this.chartOptions.yaxis[1].max=350
      this.years=categories
      const value=this.inspectionBySpan[this.year]
      this.currentYearListing={
        year:this.year,
        noDeficiencyCount:value.no_deficiency_count,
        noDeficiencyRate:value.no_deficiency_rate,
        deficiencyCount:value.with_deficiency_count,
        inspectionCount:value.inspection_count
      }
    }

    this.loading=false
  }
}
</script>

<style scoped>
</style>

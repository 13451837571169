var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-fluid px-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._m(0),_c('div',{staticClass:"row mb-2 justify-content-sm-between"},[_c('div',{staticClass:"col-auto"},[_c('nav',{staticClass:"navbar navbar-expand-lg mt-2",attrs:{"id":"ModelNav"}},[_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse justify-content-center",attrs:{"id":"navbarNav"}},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item",on:{"click":function($event){}}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: _vm.$route.name, params : {
                feature : 'number_of_report'
              }}}},[_c('span',{staticStyle:{"height":"100%"}},[_vm._v("Number of Report")])])],1),_c('li',{staticClass:"nav-item",on:{"click":function($event){}}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: _vm.$route.name, params : {
                feature : 'number_of_delay'
              }}}},[_c('span',[_vm._v("Number Of Delay")])])],1),_c('li',{staticClass:"nav-item",on:{"click":function($event){}}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: _vm.$route.name, params : {
                feature : 'delay_hours'
              }}}},[_c('span',[_vm._v("Delay Hours")])])],1)])])])])])])]),_c('router-view',{key:_vm.$route.params.feature})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-8"},[_c('h2',{staticClass:"h2 e-text-red"},[_vm._v("Ships Performance Indicator")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarNav","aria-controls":"navbarNav","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
}]

export { render, staticRenderFns }